section.formulario {
    padding: 80px 4%;
}

form {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;
}

form input,
form textarea {
    width: 100%;
    background-color: #222;
    border: 0;
    outline: 0;
    padding: 20px 15px;
    border-radius: 15px;
    color: #fff;
    font-size: 18px;
}

form textarea {
    resize: none;
    max-height: 200px;
}

form .btnEnviar {
    margin-top: 20px;
    text-align: center;
}

form .btnEnviar input[type="submit"] {
    width: 120px;
    background-color: #00FF08;
    color: #000;
    font-weight: 700;
    cursor: pointer;
    transition: .2s;
}