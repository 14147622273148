/* index.css */
.mySlides {
    width: 100%;
    height: auto;
    display: none;
    opacity: 0;
    /* transition: opacity 3s ease-in-out; */
    /* Transição suave de opacidade */
}

.mySlides img {
    width: 100%;
    height: auto;
    border: 3px solid #ccc;
    border-radius: 10px;
}

.mySlides.activeSlide {
    display: block;
    opacity: 1;
}

.dots {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;

}

.dot {
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dot.activeDot {
    background-color: #717171;
}

figcaption a {
    color: #fff;
    text-decoration: none;
}

.fade {
    animation-name: fade;
    animation-duration: 0.7s;
}

figcaption {
    margin-top: 0.3em;
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}