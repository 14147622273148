footer {
    padding: 30px 4%;
    box-shadow: 0 0 40px 10px #ffffff1d;
}

footer .flex {
    justify-content: space-between;
}

footer .lineFooter {
    padding: 20px 0;
}

.borda {
    border-top: 2px solid #00FF08;
}

footer .lineFooter p i {
    color: #00FF08;
    font-size: 22px;
}

footer .lineFooter p a {
    color: #fff;
}

@media screen and (max-width: 1020px) {
    footer .flex {
        flex-direction: column;
        gap: 30px;
    }

    footer .lineFooter {
        text-align: centers;
    }

    .logo {
        width: 100%;
        text-align: center;
        /* padding-left: 40px; */
    }    
}