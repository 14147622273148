section.especialidades {
    padding: 80px 4%;
}

section.especialidades .flex {
    gap: 20px;
}

.especialidades .especialidadesBox {
    color: #fff;
    padding: 20px;
    margin-top: 45px;
    border-radius: 20px;
    transition: .2s;
    flex: 1;
}

.especialidades .especialidadesBox:hover {
    transform: scale(1.05);
    box-shadow: 0 0 8px #ffffff78;
}

.especialidades .especialidadesBox i {
    font-size: 69px;
    color: #00FF08;
}

.especialidades .especialidadesBox h3 {
    font-size: 28px;
    margin: 15px 0;
}

.especialidades .especialidadesBox p {
    text-align: justify;
}

@media screen and (max-width: 1020px) {
    section.especialidades {
        padding: 80px 8% 0 8%;
    }

    .especialidades .especialidadesBox {
        padding: 0;
    }
}