section .topoSite {
    padding: 40px 4%;
}

section .topoSite .flex {
    align-items: center;
    justify-content: center;
    gap: 90px;
}

.topoSite h1 {
    color: #fff;
    font-size: 42px;
    line-height: 40px;
}

.topoSite .txtTopoSite h1 span {
    color: #00FF08;
}

.topoSite .txtTopoSite p {
    color: #fff;
    margin: 40px 0;
    text-align: justify;
}

@media screen and (max-width: 1020px) {
    section .topoSite {
        padding: 20px 8%;
    }

    section .topoSite .flex {
        gap: 40px;
    }

    .topoSite .flex {
        flex-direction: column-reverse;
        text-align: center;
    }

    .topoSite h1 {
        padding-top: 30px;
        font-size: 21px;
        text-align: center;
        line-height: 21px;
    }

    .topoSite .txtTopoSite p {
        padding: 0 8%;
    }

    .topoSite .btnContato {
        text-align: center;
    }
}