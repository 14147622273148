section.portfolio {
    padding: 80px 4%;
    box-shadow: 0 0 40px 10px #ffffff1d;
}

section.portfolio .flex {
    justify-content: space-around;
    margin-top: 60px;
}

.imgPort {
    width: 360px;
    height: 460px;
    background-size: cover;
    background-position: 100% 0%;
    transition: 5s;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
}

.imgPort:hover {
    background-position: 100% 100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000b8;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    opacity: 0;
    transition: .5s;
}

.overlay:hover {
    opacity: 1;
}


@media screen and (max-width: 1020px) {
    section.portfolio {
        padding: 80px 8%;
    }

    section.portfolio .flex {
        gap: 60px;
    }

    .imgPort {
        width: 100%;
        max-width: 360px;
        margin: 0 auto;
    }
}