section.sobre {
    padding: 40px 4%;
}

section.sobre .flex {
    align-items: center;
    gap: 60px;
}

.sobre .txtSobre {
    color: #fff;
}

.sobre .txtSobre h2 {
    font-size: 39px;
    line-height: 39px;
    margin-bottom: 30px;
}

.sobre .txtSobre h2 span {
    color: #00FF08;
}

.sobre .txtSobre p {
    margin: 20px 0;
    text-align: justify;
}

@media screen and (max-width: 1020px) {
    section.sobre {
        padding: 80px 8%;
    }

    .txtSobre h2 {
        font-size: 33px;
        line-height: 33px;
        text-align: center;
    }

    .imgSobre {
        width: 100%;
        max-width: 309px;
        animation: flutuar 2s ease-in-out infinite alternate;
        opacity: 0.33;
    }


    @keyframes flutuar {
        0% {
            top: 0;
        }

        100% {
            top: 30px;
        }
    }

    .imgSobre {
        text-align: center;
        width: 100%;
    }
}