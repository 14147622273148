/* ESTILO GERAL */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  background-color: #111;
  height: 100vh;
}

.interface {
  max-width: 1280px;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.btnContato button {
  padding: 10px 40px;
  font-size: 18px;
  font-weight: 600;
  background-color: #00FF08;
  border: 0;
  border-radius: 30px;
  cursor: pointer;
  transition: .2s;
}

.titulo {
  font-weight: bold;
}

h2.titulo {
  color: #fff;
  font-size: 39px;
  text-align: center;
}

h2.titulo span {
  color: #00FF08;
}

button:hover,
form .btnEnviar input[type="submit"]:hover {
  box-shadow: 0px 0px 8px #00FF0868;
  transform: scale(1.05);
}

.btnSocial button {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 0;
  background-color: #00FF08;
  font-size: 21px;
  cursor: pointer;
  margin: 0 7px;
  transform: .2s;
}

/* Estilos responsivos para dispositivos móveis */
@media screen and (max-width: 1020px) {
  /* CLASSES GERAIS */
  .flex {
    flex-direction: column;
  }

  h2.titulo {
    font-size: 33px;
    line-height: 30px;
  }

  .btnSocial {
    text-align: center;
  }
}

