/* ESTILO DO CABEÇALHO */
header {
    padding: 80px 4%;
}

header .interface {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header a {
    color: #5c5c5c;
    text-decoration: none;
    display: inline-block;
    transition: .2s;
}

header nav.menuDesktop a:hover {
    color: #fff;
    transform: scale(1.1);
}

header nav ul {
    list-style-type: none;
}

header nav.menuDesktop ul li {
    display: inline-block;
    padding: 0 40px;
    text-decoration: none;
}

/* ESTILO DO MENU MOBILE */
.btnAbrirMenu i {
    color: #00FF08;
    font-size: 40px;
}

.btnAbrirMenu {
    display: none;
}

.menuMobile {
    background-color: #000;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999;
    width: 0%;
    overflow: hidden;
    transition: .3s;
}

.menuMobile .abrirMenu {
    width: 70%;
}

.menuMobile.abrirMenu~.overlayMenu {
    display: block;
}

.menuMobile nav ul {
    text-align: right;
}

.menuMobile nav ul li a {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    padding: 20px 8%;
    display: block;
}

.menuMobile nav ul li a:hover {
    color: #000;
    background-color: #00FF08;
}

.menuMobile .btnFechar {
    padding: 20px 4%;
}

.menuMobile .btnFechar i {
    color: #00FF08;
    font-size: 30px;
}

.overlayMenu {
    background-color: #000000a7;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 888;
    display: none;
}

.btnContato button {
    padding: 10px 40px;
    font-size: 18px;
    font-weight: 600;
    background-color: #00FF08;
    border: 0;
    border-radius: 30px;
    cursor: pointer;
    transition: .2s;
}

@media screen and (max-width: 1020px) {
    .logo {
        width: 100%;
        text-align: center;
        padding-left: 40px;
    }

    .menuDesktop,
    .btnContato {
        display: none;
    }

    .btnAbrirMenu {
        display: block;
    }
}